import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { SubscriptionInterface } from '@interfaces/subscription.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(private http: HttpClient) {}
  
  createSubscriptionCheckoutUrl(packageType: string) {
    return this.http.post(environment.urlSubscriptionCheckoutUrl, {
      packageType
    });
  }

  getSubscriptionPlans() {
    return this.http.get(environment.urlSubcriptionPlans);
  }

  getUserSubscription(): Observable<SubscriptionInterface> {
    return this.http.get<SubscriptionInterface>(environment.urlGetSubscription);
  }

  createCustomerPortalSession() {
    return this.http.post(environment.urlCreateCustomerPortalSession, {});
  }
}
