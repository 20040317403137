import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { ApiResponseInterface } from '@interfaces/response.interface';

interface ResponseType {
  id: number;
  error: string;
  errorCode: string;
}

interface ResponseTypeVerify {
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private http: HttpClient) {}

  register(user) {
    return this.http.post<ResponseType>(environment.urlRegister, user);
  }

  verify(token) {
    return this.http.post<ApiResponseInterface>(environment.urlVerifyAccount, token);
  }

  emailCheckRequest(email) {
    return this.http.get<ResponseType>(environment.urlEmailCheck, {
      params: {
        email
      }
    });
  }
}
