import AppAction from '@state/actions';
import { AssetInterface } from '@interfaces/asset.interface';
import PropertyInterface from '@interfaces/property.interface';
import { Coordinates } from '@interfaces/map.interface';

export const LOAD_USER_PROPERTIES_START = 'LOAD_USER_PROPERTIES_START';
export const LOAD_USER_PROPERTIES_DONE = 'LOAD_USER_PROPERTIES_DONE';
export const DELETE_PROPERTY_ASSET = 'DELETE_PROPERTY_ASSET';

export const EDIT_PROPERTY = 'EDIT_PROPERTY';
export const LOAD_PROPERTY_START = 'LOAD_PROPERTY_START';
export const LOAD_PROPERTY_SUCCESS = 'LOAD_PROPERTY_SUCCESS';
export const LOAD_PROPERTY_FAIL = 'LOAD_PROPERTY_FAIL';
export const GEO_DECODE_PROPERTY_ADDRESS = 'GEO_DECODE_PROPERTY_ADDRESS';

export const SAVE_PROPERTY_START = 'SAVE_PROPERTY_START';
export const SAVE_PROPERTY_SUCCESS = 'SAVE_PROPERTY_SUCCESS';
export const SAVE_PROPERTY_FAIL = 'SAVE_PROPERTY_FAIL';

export const DELETE_SELECTED_PROPERTIES = 'DELETE_SELECTED_PROPERTIES';
export const DELETE_SELECTED_PROPERTIES_DONE = 'DELETE_SELECTED_PROPERTIES_DONE';
export const DELETE_SELECTED_PROPERTIES_FAIL = 'DELETE_SELECTED_PROPERTIES_FAIL';

export const SET_PROPERTIES_STATUS = 'SET_PROPERTIES_STATUS';
export const SET_PROPERTIES_STATUS_DONE = 'SET_PROPERTIES_STATUS_DONE';
export const SET_PROPERTIES_STATUS_FAILED = 'SET_PROPERTIES_STATUS_FAILED';

export const RESTORE_USER_PROPERTIES = 'RESTORE_USER_PROPERTIES';
export const RESTORE_USER_PROPERTIES_DONE = 'RESTORE_USER_PROPERTIES_DONE';

export const buildLoadUserPropertiesStart = (filters = {}) => {
  return new AppAction(LOAD_USER_PROPERTIES_START, filters);
};

export const buildLoadUserPropertiesDone = (properties, total, agencies, error = null) => {
  return new AppAction(LOAD_USER_PROPERTIES_DONE, { properties, total, agencies, error });
};

export const buildRestoreUserProperties = (ids) => {
  return new AppAction(RESTORE_USER_PROPERTIES, ids);
}

export const buildRestoreUserPropertiesDone = () => {
  return new AppAction(RESTORE_USER_PROPERTIES_DONE, null);
}

export const buildDeleteUserProperties = (ids) => {
  return new AppAction(DELETE_SELECTED_PROPERTIES, ids);
};

export const buildDeleteSelectedPropertiesDoneAction = () => {
  return new AppAction(DELETE_SELECTED_PROPERTIES_DONE, null);
};

export const buildDeleteSelectedPropertiesFailAction = () => {
  return new AppAction(DELETE_SELECTED_PROPERTIES_FAIL, null);
};

export const buildSetPropertiesStatus = (ids, status) => {
  return new AppAction(SET_PROPERTIES_STATUS, { ids, status });
};

export const buildSetPropertiesStatusDone = (ids, status) => {
  return new AppAction(SET_PROPERTIES_STATUS_DONE, { ids, status });
};

export const buildSetPropertiesStatusFailed = (error) => {
  return new AppAction(SET_PROPERTIES_STATUS_FAILED, error);
};

export const buildDeletePropertyAsset = (asset: AssetInterface) => {
  return new AppAction(DELETE_PROPERTY_ASSET, { asset });
};

export const buildEditPropertyAction = (property: PropertyInterface) => {
  return new AppAction(EDIT_PROPERTY, { property });
};

export const buildLoadPropertyStart = (propertyId?: number) => {
  return new AppAction(LOAD_PROPERTY_START, { propertyId });
};
export const buildLoadPropertySuccess = (property: PropertyInterface) => {
  return new AppAction(LOAD_PROPERTY_SUCCESS, property);
};

export const buildSavePropertyAction = (closeOnSave = true) => {
  return new AppAction(SAVE_PROPERTY_START, { closeOnSave });
};

export const buildSavedPropertyAction = (id) => {
  return new AppAction(SAVE_PROPERTY_SUCCESS, { id });
};

export const buildSavePropertyFailAction = (data) => {
  return new AppAction(SAVE_PROPERTY_FAIL, data);
};

export const buildGeoDecodePropertyAddress = (coord: Coordinates) => {
  return new AppAction(GEO_DECODE_PROPERTY_ADDRESS, coord);
}