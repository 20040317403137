import AppAction from '@state/actions';

export const ADMIN_GET_USERS_START = 'ADMIN_GET_USERS_START';
export const ADMIN_GET_USERS_SUCCESS = 'ADMIN_GET_USERS_SUCCESS';
export const ADMIN_GET_USERS_FAILED = 'ADMIN_GET_USERS_FAILED';

export const buildAdminGetUsersStart = () => {
  return new AppAction(ADMIN_GET_USERS_START, null);
};

export const buildAdminGetUsersSuccess = userList => {
  return new AppAction(ADMIN_GET_USERS_SUCCESS, userList);
};

export const buildAdminGetUsersFailed = error => {
  return new AppAction(ADMIN_GET_USERS_FAILED, error);
};
