import AppAction from '@state/actions';
import UserInterface from '@interfaces/user.interface';

export const REGISTER_START = 'REGISTER_START';
export const REGISTER_FORM = 'REGISTER_FORM';
export const REGISTER_DONE = 'REGISTER_DONE';
export const REGISTER_FAIL = 'REGISTER_FAIL';

export const USER_START_LOAD_DATA = 'USER_START_LOAD_DATA';
export const USER_DATA_LOAD_SUCCESS = 'USER_DATA_LOAD_SUCCESS';
export const USER_DATA_LOAD_FAILED = 'USER_DATA_LOAD_FAILED';

export const USER_DATA_SAVE_START = 'USER_DATA_SAVE_START';
export const USER_DATA_SAVE_SUCCESS = 'USER_DATA_SAVE_SUCCESS';
export const USER_DATA_SAVE_FAILED = 'USER_DATA_SAVE_FAILED';

export const USER_FINGERPRINT = 'USER_FINGERPRINT';

export const buildRegisterForm = (data, isValid) => {
  return new AppAction(REGISTER_FORM, { data, isValid });
};

export const buildRegisterStart = () => {
  return new AppAction(REGISTER_START, null);
};

export const buildRegisterDone = (params) => {
  return new AppAction(REGISTER_DONE, params);
};

export const buildRegisterFail = (err) => {
  return new AppAction(REGISTER_FAIL, err);
};

export const buildStartLoadUserData = () => {
  return new AppAction(USER_START_LOAD_DATA, null);
};

export const buildSetUserData = (user: UserInterface) => {
  return new AppAction(USER_DATA_LOAD_SUCCESS, user);
};

export const buildLoadUserDataFailed = (error: string) => {
  return new AppAction(USER_DATA_LOAD_FAILED, error);
};

export const buildSaveUserData = (user: UserInterface) => {
  return new AppAction(USER_DATA_SAVE_START, user);
};

export const buildUserDataSaved = () => {
  return new AppAction(USER_DATA_SAVE_SUCCESS, null);
};

export const buildUserDataFailed = (error) => {
  return new AppAction(USER_DATA_SAVE_FAILED, error);
};

export const buildUserFingerPrint = (fingerprint, timezone, userAgent, version) => {
  return new AppAction(USER_FINGERPRINT, { fingerprint, timezone, userAgent, version });
};

