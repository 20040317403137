import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import UserInterface from '@interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUserData() {
    return this.http.get<UserInterface>(environment.urlGetUserData);
  }

  saveUserData(data: UserInterface) {
    return this.http.post(environment.urlSaveUserData, data);
  }

  changePassword(data) {
    return this.http.post(environment.urlChangeUserPassword, data);
  }

  forgotPassword(data) {
    return this.http.post(environment.urlForgotPassword, data);
  }

  closeAccount(data) {
    return this.http.post(environment.urlCloseAccount, data);
  }
}
